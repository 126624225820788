<template>
    <div>
        <!-- START STEP 1-->
        <section class="pedido-steps" v-if="!form_success">
            <div class="container">
                <div class="row">
                    <div class="col-12">						
                        <div class="return d-block d-sm-none">
                            <router-link :to="route">
                                <img :src="require('@/assets/img/backspace.svg')" class="mb-5">
                            </router-link>
                        </div>
                        <div class="section-title">
                            <h4>Steps de Registro Sanitario {{ $route.params.numero }}</h4>
                            <img class="d-none d-sm-block" @click="cancel" :src="require('@/assets/img/backspace.svg')">
                        </div>
                        <p class="subtitulo">En esta sección puede modificar los steps del registro sanitario</p>
                    </div>		
                </div>
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" id="lead-time">
                                <label><strong>Estado: </strong>{{ data.status?.status}}{{ data.status?.sub_status ? ` - ${data.status.sub_status}` : ``}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="bg-white d-none d-sm-table" id="pedidosTable">
                                <thead>
                                    <tr>
                                        <th class="text-center"><strong>Step</strong></th>
                                        <th class="text-center"><strong>Descripcion</strong></th>
                                        <th class="text-center"><strong>Check</strong></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="b-top" v-for="(step, key, index) in steps" :key="index">
                                        <td class="text-center">{{step.step_number}}</td>
                                        <td>{{step.task}}</td>
                                        <td class="text-center">
                                            <div class="inline-flex align-center label-content justify-center">
                                                <input type="checkbox" :id="key" :value="step.id" v-model.lazy="formData[step.id]">
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="bg-white d-block d-sm-none pedidosTableMobile b-top" 
                                v-for="(step, key, index) in steps" :key="index">
                                <tr>
                                    <th class="text-center"><strong>Step</strong></th>
                                    <td class="text-center">{{step.step_number}}</td>
                                </tr>
                                <tr>
                                    <th class="text-center"><strong>Descripcion</strong></th>
                                    <td class="text-center">{{step.task}}</td>
                                </tr>
                                <tr>
                                    <th class="text-center"><strong>Check</strong></th>
                                    <td class="text-center"><input type="checkbox" :id="key" :value="step.id" v-model.lazy="formData[step.id]"></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </form>	
                <div class="loading"></div>
                <div class="row">
                    <div class="col-md-12 next-btn">
                        <a href="javascript:void(0)" @click="submitForm()" class="btn-green">Guardar</a>
                        <a @click="cancel()" href="javascript:void(0)" class="btn-white">Cancelar</a>
                    </div>
                </div>
            </div>
        </section>

    </div>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>

<script>
    import { route } from "../../../enviroment.js"
    import axios from 'axios'
    import $ from 'jquery'

    export default {
        name: 'RegistroSanitarioEdicion',
        data: function () {
            return {
                route: route,
                data: this.defaultFieldsValues(),
                steps: [],
                formData: [],
                submit_disabled: false,
                form_success: false
            }
        },
        components: {
        },
        async created(){
            await this.getUser()
            await this.getRegistro();
        },
        async mounted(){
            //
        },
        computed: {
            //
        },
        methods: {
            showAlert(type, title, message) {
                this.$refs.alert.showAlert(
                    type, // There are 4 types of alert: success, info, warning, error
                    message, // Message of the alert
                    title, // Header of the alert
                    { 
                        iconSize: 35, // Size of the icon (px)
                        iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                        position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                    } 
                )
            },
            async getSteps(registro) {
                const response = await axios.get('/steps/' + registro.id);
                return response.data.data.steps;
            },
            async cancel(){
                const confirmed = await this.cancelConfirm();
                if (!confirmed) {
                    return;
                }
                location.href = route + 'registro-sanitario-detalle/' + this.$route.params.id;
            },
            mostrarCarga(){
                $('.loading').removeClass('d-none');
                $('#mostrarTablaPedidos').addClass('d-none');
            },
            ocultarCarga(){
                $('.loading').addClass('d-none');
                $('#mostrarTablaPedidos').removeClass('d-none');
            },
            submitForm(){
                this.submit_disabled = true;
                axios.put(`/steps/${this.data.id}`, {
                    "steps": Object.keys(this.formData).map(item=>Number(item))
                }).then(() => {
                    location.href = route + 'registro-sanitario-detalle/' + this.$route.params.id
                })
                .catch((error) => {
                    let message = '';
                    if (error.response.status == 422) {
                        message = 'Hay campos invalidos en el formulario.';
                    } else {
                        message = error.response.data.error.message.description;
                    }
                    this.showAlert('warning','Atencion!', message);
                })
                .finally(() => {
                    this.submit_disabled = false;
                });
            },
            getUser(){
                axios.get('/auth/user'
                ).then((response) => {        
                    this.user = response.data.data.profile.name;
                    const adminRol = response.data.data.profile.Admin_RS ?? null;
					const userRol = response.data.data.profile.User_RS ?? null;  
                    if (adminRol) {
                        this.rol = 'Admin';
                    } else if (userRol) {
                        location.href= route + "registro-sanitario";
                    } else {
                        location.href= route + "login";
                    }
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                });
            },
            async getRegistro() {
                axios.get('/registro-sanitario/' + this.$route.params.id
                ).then(async (response) => {
                    this.data = response.data.data;
                    const stepsIds = response.data.data.steps.map((s=>s.step_id));
                    const stepsIdsObject = stepsIds.reduce((a, v) => ({ ...a, [v]: true}), {});
                    this.formData = stepsIdsObject;
                    this.steps = await this.getSteps(this.data);
                    this.ocultarCarga()
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                });
            },
            defaultFieldsValues() {
                return {}
            },
            async cancelConfirm() {
                return await this.$vbsModal.confirm({
                    title: "Cancelar edicion de steps del registro",
                    message: "Esta seguro? Se perderan los cambios realizados.",
                    leftBtnText: 'No',
                    rightBtnText: 'Si',
                    displayCloseBtn: false
                });
            },
        },		
    }
</script>