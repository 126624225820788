/* eslint-disable */
<template>
    <div>
        <!-- START STEP 1-->
        <section class="pedido-steps" v-if="!form_success">
            <div class="container">
                <div class="row">
                    <div class="col-12">						
                        <div class="return d-block d-sm-none">
                            <router-link :to="route">
                                <img :src="require('@/assets/img/backspace.svg')" class="mb-5">
                            </router-link>
                        </div>
                        <div class="section-title">
                            <h4>Edicion de Registro Sanitario {{ $route.params.id }}</h4>
                            <img class="d-none d-sm-block" @click="cancelRS" :src="require('@/assets/img/backspace.svg')">
                        </div>
                        <p class="subtitulo">En esta sección puede editar el registro sanitario</p>
                    </div>		
                </div>
                <form :action="route + 'pedido-confirmado'" method="GET" id="form-id">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group" id="number-rs">
                                <label>Numero Registro<Tooltip :parent="'number-rs'" :message="'Numero de Registro Sanitario'">*</Tooltip></label>
                                <input type="text" id="number-rs" name="number-rs" v-model="data.number">
                                <span class="required-span" v-if="this.errorsForm.number">El Numero es obligatorio o ya existe.</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="channels">
                                <label>Canal <Tooltip :parent="'channels'" :message="'Canal de Venta en Mccain.'">*</Tooltip></label>
                                <select name="channels" v-model="data.channel_id">
                                    <option v-for="(channel,index) in this.formData.channels" :key="index" :value="channel.id">{{channel.channel}}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.channel">El canal es obligatorio</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" id="distributors">
                                <label>Distribuidor <Tooltip :parent="'distributors'" :message="'Distribuidor Autorizado en el registro sanitario.'">*</Tooltip></label>
                                <select name="distributors" v-model="data.distributors" multiple>
                                    <option :key="option" v-for="option in distributorOptions" :value="option.value">{{ option.text }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.distributors">Distribuidor es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" id="sku">
                                <label>SKU <Tooltip :parent="'sku'" :message="'Numero de identificación interno Mccain.'">*</Tooltip></label>
                                <select name="sku" v-model="data.skus" multiple>
                                    <option :key="sku" v-for="sku in skusOptions" :value="sku.value">{{ sku.text }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.skus">El SKU es obligatorio</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group" id="countries">
                                <label>Source <Tooltip :parent="'sources'" :message="'País de Producción del Producto.'">*</Tooltip></label>
                                <select name="sources" v-model="data.sources" multiple>
                                    <option :key="source"  v-for="source in sourcesOptions" :value="source.value">{{ source.text }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.sources">El source es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group" id="commercial_countries">
                                <label>País de Venta <Tooltip :parent="'commercial_countries'" :message="'Paises con Registros Sanitarios para la Venta.'">*</Tooltip></label>
                                <select name="commercial_countries" v-model="data.commercial_countries" multiple>
                                    <option :key="commercial_country"  v-for="commercial_country in countriesOptions" :value="commercial_country.value">{{ commercial_country.text }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.commercial_countries">El country es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="status">
                                <label>Estado <Tooltip :parent="'status'" :message="'Estado del Registro.'">*</Tooltip></label>
                                <select name="status" v-model="data.status_id">
                                    <option v-for="(status,index) in this.formData.statuses" :key="index" :value="status.id">{{ status.status}}{{ status.sub_status ? ` - ${status.sub_status}` : ``}}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.status_id">El estado y subestado es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="sub_status">
                                <label>Sub Estado <Tooltip :parent="'sub_status'" :message="'Estatus de Procesos  en el registro.'">*</Tooltip></label>
                                <select name="status" v-model="data.sub_status_id">
                                    <option v-for="(sub_status,index) in this.formData.sub_statuses" :key="index" :value="sub_status.id">{{ sub_status.name }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.sub_status_id">El subestado es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="status_commercial">
                                <label>Estado Comercial <Tooltip :parent="'status_commercial'" :message="'Estatus del Registro para Ventas.'">*</Tooltip></label>
                                <select name="status_commercial" v-model="data.status_commercial_id">
                                    <option v-for="(status_commercial,index) in this.formData.statuses_commercial" :key="index" :value="status_commercial.id">{{ status_commercial.name }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.status_commercial_id">El estado y subestado es obligatorio</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group" id="fecha_vencimiento">
                                <label>Fecha de vencimiento <Tooltip :parent="'fecha_vencimiento'" :message="'Fecha de Vencimiento del Registro Sanitario.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.expiration_date"
                                    name="fecha-vencimiento"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.expiration_date">{{ this.errorsForm.expiration_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="change_date">
                                <label>Fecha de cambio <Tooltip :parent="'fecha_cambio'" :message="'Fecha de Adición de Distribuidor.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.change_date"
                                    name="fecha-cambio"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.change_date">{{ this.errorsForm.change_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="fecha_lanzamiento">
                                <label>Fecha de finalizacion <Tooltip :parent="'fecha_finish'" :message="'Fecha de culminación de Innovación.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.finish_date"
                                    name="fecha-finish"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.finish_date">{{ this.errorsForm.finish_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="fecha_actualization">
                                <label>Fecha de actualizacion <Tooltip :parent="'fecha_actualization'" :message="'Fecha de Update del Registro.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.actualization_date"
                                    name="fecha-actualization"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.actualization_date">{{ this.errorsForm.actualization_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group consolidar-carga" id="certificado-religioso">
                                <label style="width: 100%;">Certificado Religioso <Tooltip :parent="'certificado-religioso'" :message="'Certificado Koser, Halal , vegano, U Ortodoxa.'">*</Tooltip></label>
                                <input type="checkbox" v-model="data.religious_certificate" 
                                    true-value="1"
                                    false-value="0">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" id="comentarios">
                                <label>Comentarios <Tooltip :parent="'comentarios'" :message="'Notas adicionales del  Registro.'">*</Tooltip></label>
                                <textarea rows="5" type="text" id="comentarios" name="comentarios" v-model="data.comment"></textarea>
                                <span class="required-span" v-if="this.errorsForm.comment">El comentario es invalido</span>
                            </div>
                        </div>
                    </div>
                </form>	
                <div class="row">
                    <div class="col-md-12 next-btn">
                        <a href="javascript:void(0)" @click="submitForm()" v-if="rol=='Admin'" class="btn-green">Guardar</a>
                        <a @click="cancelRS()" href="javascript:void(0)" class="btn-white">Cancelar</a>
                    </div>
                </div>
            </div>
        </section>

    </div>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>

<script>
    import { route } from "../../../enviroment.js"
    import axios from 'axios'
    import $ from 'jquery'
    import Tooltip from "./components/Tooltip.vue"

    export default {
        name: 'RegistroSanitarioEdicion',
        data: function () {
            return {
                route: route,
                data: this.defaultFieldsValues(),
                errorsForm: this.defaultFieldsValues(),
                distributorOptions: [],
                rol: 'User',
                skusOptions: [],
                countriesOptions: [],
                sourcesOptions: [],
                formData: {
                    skus: [],
                    distributors: [],
                    channels: [],
                    countries: [],
                    statuses: [],
                    statuses_commercial: [],
                },
                submit_disabled: false,
                form_success: false
            }
        },
        components: {
            Tooltip
        },
        async created(){
            await this.getUser()
            await this.getRegistro();
        },
        async mounted(){
            await this.getData();
            
        },
        computed: {
            //
        },
        methods: {
            showAlert(type, title, message) {
                this.$refs.alert.showAlert(
                    type, // There are 4 types of alert: success, info, warning, error
                    message, // Message of the alert
                    title, // Header of the alert
                    { 
                        iconSize: 35, // Size of the icon (px)
                        iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                        position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                    } 
                )
            },
            async getData() {
                axios.get('/data')
                .then((response) => {
                    if(response.data.data != ""){
                        this.formData = response.data.data;
                        this.distributorOptions = response.data.data.distributors.map(d=> {
                            return {
                                text: `${d.code_distributor} - ${d.name_distributor}`,
                                value: `${d.code_distributor}`
                            }
                        });
                        this.skusOptions = response.data.data.skus.map(s=>{
                            return {
                                text: `${s.code_sku} - ${s.name_sku}`,
                                value: `${s.code_sku}`
                            }
                        });
                        this.countriesOptions = response.data.data.commercial_countries.map(c=>{
                            return {
                                text: `${c.name}`,
                                value: `${c.id}`
                            }
                        });
                        this.sourcesOptions = response.data.data.sources.map(source=>{
                            return {
                               text: `${source.name}`,
                               value: `${source.id}`
                            }
                        });
                    }
                    this.ocultarCarga()
                })
                .catch((error) => {
                    this.showAlert('warning','Atencion!', error.response.data.error.message);
                });
            },
            async cancelRS(){
                const confirmed = await this.cancelConfirm();
                if (!confirmed) {
                    return;
                }
                location.href = route + 'registro-sanitario-detalle/' + this.$route.params.id;
            },
            mostrarCarga(){
                $('.loading').removeClass('d-none');
                $('#mostrarTablaPedidos').addClass('d-none');
            },
            ocultarCarga(){
                $('.loading').addClass('d-none');
                $('#mostrarTablaPedidos').removeClass('d-none');
            },
            submitForm(){
                this.submit_disabled = true;
                const dataToSend = {...this.data};
                // eslint-disable-next-line
                axios.put(`/registro-sanitario/${this.data.id}`, Object.fromEntries(Object.entries(dataToSend).filter(([k,v]) => v))
                ).then(() => {
                    this.errorsForm = this.defaultFieldsValues();
                    location.href = route + 'registro-sanitario-detalle/' + this.$route.params.id
                })
                .catch((error) => {
                    let message = '';
                    if (error.response.status == 422) {
                        this.errorsForm = error.response.data.errors;
                        message = 'Hay campos invalidos en el formulario.';
                    } else {
                        this.errorsForm = this.defaultFieldsValues();
                        message = error.response.data.error.message.description;
                    }
                    this.showAlert('warning','Atencion!', message);
                })
                .finally(() => {
                    this.submit_disabled = false;
                });
            },
            getUser(){
                axios.get('/auth/user'
                ).then((response) => {        
                    this.user = response.data.data.profile.name;
                    const adminRol = response.data.data.profile.Admin_RS ?? null;
					const userRol = response.data.data.profile.User_RS ?? null;  
                    if (adminRol) {
                        this.rol = 'Admin';
                    } else if (userRol)  {
                        location.href= route + "registro-sanitario";
                    } else {
                        location.href= route + "login";
                    }
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                });
            },
            getRegistro() {
                axios.get('/registro-sanitario/' + this.$route.params.id
                ).then((response) => {
                    this.data = response.data.data;
                    this.data.distributors = response.data.data.distributors.map(d=>d.code_distributor);
                    this.data.skus = response.data.data.skus.map(s=>s.code_sku);
                    this.data.commercial_countries = response.data.data.commercial_countries.map(c=>c.commercial_country_id);
                    this.data.sources = response.data.data.sources.map(s=>s.source_id);
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                });
            },
            defaultFieldsValues() {
                return {
                    number: '',
                    channel_id: '',
                    distributors: '',
                    skus: '',
                    countries: '',
                    status_id: '',
                    expiration_date: '',
                    change_date: '',
                    finish_date: '',
                    actualization_date: '',
                    status_commercial_id: '',
                    comment: '',
                    religious_certificate: 0
                }
            },
            
            async cancelConfirm() {
                return await this.$vbsModal.confirm({
                    title: "Cancelar edicion registro",
                    message: "Esta seguro? Se perderan los cambios realizados.",
                    leftBtnText: 'No',
                    rightBtnText: 'Si',
                    displayCloseBtn: false
                });
            },
        },		
    }
</script>