<template>
    <input type="file" name="file" @change="onChange($event)">
</template>

<script>
export default {
    name: "FileUpload",
    props: ['modelValue'],
    methods: {
        onChange(event) {
            this.$emit('update:modelValue', event.target.files[0]);
        },
    },
};
</script>