<template>
   <section class="password-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>Recuperar contraseña</h4>
                    <form @submit.prevent="onSubmit">
                        <div class="row">
                            <div class="col-md-12">
                                <input v-model="password" type="password" placeholder="Nueva Contraseña" name="" required>
                            </div>
                            <div class="col-md-12">
                                <input v-model="confirmation" type="password" placeholder="Confirme Contraseña" name="" required>
                            </div>
                            <div class="col-md-12">
                                <p v-if="this.error_message!=''" class="password-forget invalid-password" style="color: red;">{{ error_message }}</p>
                            </div>
                            <div class="col-md-12">
                                <button @click="resetPassword()">Enviar</button>
                            </div>
                        </div>
                    </form>
                    <div class="volver">
                        <a :href="route + 'login'">
                            <span>
                                <img :src="require('@/assets/img/backspace.svg')">
                                Volver a página de inicio
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>
<script>
import axios from 'axios'
import { route } from "../../enviroment.js"
export default {
	name: "ForgotPassword",
	props: {

	},
	data() {
		return {
            route : route,
            password : '',
            confirmation : '',
            error_message: ''
		}
	},
    created(){
    },
    methods:{
        showAlert(type, title, message) {
            this.$refs.alert.showAlert(
                type, // There are 4 types of alert: success, info, warning, error
                message, // Message of the alert
                title, // Header of the alert
                { 
                    iconSize: 35, // Size of the icon (px)
                    iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                    position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                } 
            )
        },
        resetPassword(){
            let searchParams = new URLSearchParams(window.location.search.substring(1));
            const principal = searchParams.get("principal");
            const token = searchParams.get("token");
            if (this.password == '' || this.confirmation == '') {
                this.error_message = "Las contraseñas son requeridas.";
                return false;
            }

            if (this.confirmation != this.password) {
                this.error_message = "Las contraseñas no coinciden.";
                return false;
            }

            if (!principal || !token) {
                this.error_message = "El link expiro. Intente reenviar el email de recuperacion de password nuevamente.";
                return false;
            }

            axios.post('/auth/password/reset', 
                {
                    "principal": principal,
                    "code": token,
                    "password": this.password,
                    "confirmation": this.confirmation
                }
            ).then((response) => {
                if (response.data.data.status=='error') {
                    this.error_message = "Ocurrio un error. Intente reenviar el email de recupero de password nuevamente.";
                    return false;
                }
                this.showAlert("success","Exito!","Su contraseña ha sido reestablecida");
                location.href= "" + route + "";
            })
            .catch(() => {
                this.error_message = "Ocurrio un error. Intente reenviar el email de recupero de password nuevamente.";
            });
        }
    }
}
</script>