<template>
    <section class="pedido-enviado">
        <div class="container">
            <div class="row" :style="'margin-top: 50px;'">
                <div class="col-12">
                    <div class="return d-block d-sm-none">
                        <img :src="require('@/assets/img/backspace.svg')">
                    </div>
                    <span class="check"><img :src="require('@/assets/img/check.svg')"></span>
                    <div class="listo">
                        <h3>¡Listo!</h3>
                        <p>El registro sanitario fue creado con exito!</p>
                        <router-link :to="route + 'registro-sanitario'" class="btn-yellow">
                            Entendido
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { route } from "../../../enviroment.js"
export default {
    name: "RegistroSanitarioListo",
    data() {
        return {
            route : route,
        }
    },
}
</script>