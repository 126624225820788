/* eslint-disable */
<template>
    <main-banner :banners="banners"></main-banner>
    <section class="pedidos-section">
        <div class="container">
            <div class="section-title">
                <h4>Registros Sanitarios</h4>
            </div>
            <div class="row">
                <div class="col-md-4 index-option">
                    <div class="box-pedido">
                        <router-link :to="route + 'registro-sanitario'">
                            <img :src="require('@/assets/img/pedidos1.png')">
                            <h3>Registros Sanitarios</h3>
                        </router-link>
                    </div>
                </div>
                <!-- <div class="col-md-4 index-option">
                    <div class="box-pedido">
                        <router-link :to="route + 'pedido-nuevo'">
                            <img :src="require('@/assets/img/pedidos1.png')">
                            <h3>Seccion Users</h3>
                        </router-link>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
</template>
<script>
	import { route } from "../../enviroment.js"
    import MainBanner from "../layouts/MainBanner.vue"
    import axios from 'axios'
    export default {
        name: "HomePage",
        components: {
            MainBanner
        },
        props: {

        },
        data() {
            return {
                route: route,
                banners: [],
                rol: null
            }
        },
        created(){
            this.getUser()
            //this.getBanners()
        },
        methods:{
            getUser(){
                axios.get('/auth/user'
                ).then((response) => {        
                    this.user = response.data.data.profile.name;
                    const adminRol = response.data.data.profile.Admin_RS ?? null;
					const userRol = response.data.data.profile.User_RS ?? null;  
                    if (adminRol || userRol) {
                        this.rol = adminRol ? 'Admin_RS' : 'User_RS';
                    } else {
                        location.href= route + "login";
                    }
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                });
            },
            getBanners() {
                axios.get('/banners')
                .then((response) => {
                    this.banners = response.data.data.banners
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                })
            }
        }
    }
</script>