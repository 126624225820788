<template>
   <section class="password-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4>¿Olvidaste tu contraseña?</h4>
                    <p>No te preocupes, te enviaremos un correo con instrucciones para resetearla.</p>
                    <form @submit="requestPassword()">
                        <input v-model="this.principal" type="" name="" placeholder="Correo electrónico">
                        <button>Enviar</button>
                    </form>
                    <div class="volver">
                        <a :href="route + 'login'">
                            <span>
                                <img :src="require('@/assets/img/backspace.svg')">
                                Volver a página de inicio
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import { route } from "../../enviroment.js"
export default {
	name: "ForgotPassword",
	props: {

	},
	data() {
		return {
            route : route,
            principal : ''
		}
	},
    created(){
    },
    methods:{
        requestPassword(){
            axios.post('/auth/password/recovery', 
                {
                    "principal":this.principal
                }
            ).then(() => {
                alert("Su contraseña ha sido reestablecida")
                location.href= "" + route + "";
            })
            .catch(function () {
            });
        }
    }
}
</script>