<template>
    <Splide :options="options">
        <SplideSlide v-for="(banner,index) in banners" :key="index">
            <img class="main-banner-img-desktop" :src="banner.url">
        </SplideSlide>
    </Splide>

    <Splide :options="options_mobile">
        <SplideSlide v-for="(banner,index) in banners" :key="index">
            <img class="main-banner-img-mobile" :src="banner.url_mobile">
        </SplideSlide>
    </Splide>

</template>

<script>

import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';

export default {
    name: "MainBanner",
    props: ["banners"],
    components: { 
        Splide, 
        SplideSlide 
    },
    setup() {

        const options = {
            autoplay: true,
            rewind: false,
            arrows: false,
            pagination: false,
            role: 'loop',
            fixedWidth: '100%',
            perPage: 1
        }

        const options_mobile = {
            autoplay: true,
            rewind: false,
            arrows: false,
            pagination: false,
            role: 'loop',
            fixedWidth: '100%',
            perPage: 1
        }
        return { options, options_mobile }
    }
}

</script>