<template>
    <div>
        <!-- START STEP 1-->
        <section class="pedido-steps" v-if="!form_success">
            <div class="container">
                <div class="row">
                    <div class="col-12">						
                        <div class="return d-block d-sm-none">
                            <router-link :to="route">
                                <img :src="require('@/assets/img/backspace.svg')" class="mb-5">
                            </router-link>
                        </div>
                        <div class="section-title">
                            <h4>Creación de Registro Sanitario</h4>
                            <router-link :to="route + 'registro-sanitario'">
                                <img class="d-none d-sm-block" :src="require('@/assets/img/backspace.svg')">		
                            </router-link>
                        </div>
                        <p class="subtitulo">En esta sección puede crear un nuevo registro sanitario</p>
                    </div>		
                </div>
                <form :action="route + 'pedido-confirmado'" method="GET" id="form-id">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group" id="number-rs">
                                <label>Numero Registro <Tooltip :parent="'number-rs'" :message="'Numero de Registro Sanitario.'">*</Tooltip></label>
                                <input type="text" id="number-rs" name="number-rs" v-model="data.number">
                                <span class="required-span" v-if="this.errorsForm.number">El Numero es obligatorio o ya existe.</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="channels">
                                <label>Canal <Tooltip :parent="'channels'" :message="'Canal de Venta en Mccain.'">*</Tooltip></label>
                                <select name="channels" v-model="data.channel_id">
                                    <option v-for="(channel,index) in this.formData.channels" :key="index" :value="channel.id">{{channel.channel}}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.channel_id">El canal es obligatorio</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" id="distributors">
                                <label>Distribuidor</label>
                                    <VueMultiselect
                                        v-model="data.distributors"
                                        :options="formData.distributors"
                                        :multiple="true"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        track-by="code_distributor"
                                        :custom-label="({ code_distributor, name_distributor }) => `${code_distributor} - ${name_distributor}`"
                                        placeholder="Seleccione un Distribuidor"
                                    />
                                <span class="required-span" v-if="errorsForm.distributors">Distribuidor es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" id="sku">
                                <label>SKU</label>
                                    <VueMultiselect
                                        v-model="data.skus"
                                        :options="formData.skus"
                                        :multiple="true"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :show-labels="false"
                                        track-by="code_sku"
                                        :custom-label="({ code_sku, name_sku }) => `${code_sku} - ${name_sku}`"
                                        placeholder="Seleccione un SKU"
                                    />
                                <span class="required-span" v-if="errorsForm.skus">El SKU es obligatorio</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group" id="sources">
                                <label>Source <Tooltip :parent="'sources'" :message="'País de Producción del Producto.'">*</Tooltip></label>
                                <select name="sources" v-model="data.sources" multiple>
                                    <option v-for="(source,index) in this.formData.sources" :key="index" :value="source.id">{{source.name}}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.sources">El source es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group" id="commercial_countries">
                                <label>País de Venta <Tooltip :parent="'commercial_countries'" :message="'Paises con Registros Sanitarios para la Venta.'">*</Tooltip></label>
                                <select name="commercial_countries" v-model="data.commercial_countries" multiple>
                                    <option v-for="(commercial_country,index) in this.formData.commercial_countries" :key="index" :value="commercial_country.id">{{ commercial_country.name }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.commercial_countries">El commercial country es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="status">
                                <label>Estado <Tooltip :parent="'status'" :message="'Estado del Registro.'">*</Tooltip></label>
                                <select name="status" v-model="data.status_id">
                                    <option v-for="(status,index) in this.formData.statuses" :key="index" :value="status.id">{{ status.status }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.status_id">El estado es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="status">
                                <label>Sub Estado <Tooltip :parent="'sub_status'" :message="'Estatus de Procesos  en el registro.'">*</Tooltip></label>
                                <select name="sub_status" v-model="data.sub_status_id">
                                    <option v-for="(sub_status,index) in this.formData.sub_statuses" :key="index" :value="sub_status.id">{{ sub_status.name }}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.sub_status_id">El sub estado es obligatorio</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="status_commercial">
                                <label>Estado Comercial <Tooltip :parent="'status_commercial'" :message="'Estatus del Registro para Ventas.'">*</Tooltip></label>
                                <select name="status_commercial" v-model="data.status_commercial_id">
                                    <option v-for="(status_commercial,index) in this.formData.statuses_commercial" :key="index" :value="status_commercial.id">{{ status_commercial.name}}</option>
                                </select>
                                <span class="required-span" v-if="this.errorsForm.status_commercial_id">El estado y subestado es obligatorio</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group" id="fecha_vencimiento">
                                <label>Fecha de vencimiento <Tooltip :parent="'fecha_vencimiento'" :message="'Fecha de Vencimiento del Registro Sanitario.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.expiration_date"
                                    name="fecha-vencimiento"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.expiration_date">{{ this.errorsForm.expiration_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="fecha_cambio">
                                <label>Fecha de cambio <Tooltip :parent="'fecha_cambio'" :message="'Fecha de Adición de Distribuidor.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.change_date"
                                    name="fecha-cambio"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.change_date">{{ this.errorsForm.change_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="fecha_finish">
                                <label>Fecha de Finalizacion <Tooltip :parent="'fecha_finish'" :message="'Fecha de culminación de Innovación.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.finish_date"
                                    name="fecha-finish"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.finish_date">{{ this.errorsForm.finish_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group" id="fecha_finish">
                                <label>Fecha de Actualizacion <Tooltip :parent="'fecha_actualization'" :message="'Fecha de Update del Registro.'">*</Tooltip></label>
                                <Datepicker
                                    v-model="data.actualization_date"
                                    name="fecha-actualization"
                                    :enableTimePicker="false"
                                    textInput
                                    autoApply
                                    format="dd/MM/yyyy"
                                    inputClassName="dp-input-custom"
                                    utc="preserve"
                                    required
                                />
                                <span class="required-span" v-if="this.errorsForm.actualization_date">{{ this.errorsForm.actualization_date[0] }}</span>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group consolidar-carga" id="certificado-religioso">
                                <label style="width: 100%;">Certificado Religioso <Tooltip :parent="'certificado-religioso'" :message="'Certificado Koser, Halal , vegano, U Ortodoxa.'">*</Tooltip></label>
                                <input type="checkbox" v-model="data.religious_certificate">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group" id="comentarios">
                                <label>Comentarios <Tooltip :parent="'comentarios'" :message="'Notas adicionales del  Registro.'">*</Tooltip></label>
                                <textarea rows="5" type="text" id="comentarios" name="comentarios" v-model="data.comment"></textarea>
                                <span class="required-span" v-if="this.errorsForm.comment">El comentario es invalido</span>
                            </div>
                        </div>
                    </div>
                </form>	
                <div class="row">
                    <div class="col-md-12 next-btn">
                        <a href="javascript:void(0)" @click="submitForm()" class="btn-green">Guardar</a>
                        <a @click="cancelRS()" href="javascript:void(0)" class="btn-white">Cancelar</a>
                    </div>
                </div>
            </div>
        </section>

        <RegistroSanitarioListo v-if="form_success"></RegistroSanitarioListo>

    </div>
    <vue-basic-alert :duration="300" :closeIn="1000000" ref="alert" />
</template>

<script>
    import { route } from "../../../enviroment.js"
    import RegistroSanitarioListo from "./RegistroSanitorioListo.vue"
    import axios from 'axios'
    import $ from 'jquery'
    import Tooltip from "./components/Tooltip.vue";
    import VueMultiselect from 'vue-multiselect';
    
    export default {
        name: 'RegistroSanitarioNuevo',
        data: function () {
            return {
                route : route,
                data: this.defaultSelected(),
                errorsForm: this.defaultFieldsValues(),
                formData: {
                    skus: [],
                    distributors: [],
                    channels: [],
                    commercial_countries: [],
                    sources: [],
                    statuses: [],
                    statuses_commercial: [],
                },
                submit_disabled: false,
                form_success: false
            }
        },
        components: {
            RegistroSanitarioListo,
            Tooltip,
            VueMultiselect
        },
        created(){
            this.getUser()
        },
        mounted(){
            this.getData()
        },
        computed: {
            //
        },
        methods: {
            showAlert(type, title, message) {
                this.$refs.alert.showAlert(
                    type, // There are 4 types of alert: success, info, warning, error
                    message, // Message of the alert
                    title, // Header of the alert
                    { 
                        iconSize: 35, // Size of the icon (px)
                        iconType: 'solid', // Icon styles: now only 2 styles 'solid' and 'regular'
                        position: 'top right' // Position of the alert 'top right', 'top left', 'bottom left', 'bottom right' 
                    } 
                )
            },
            async getData() {
                axios.get('/data')
                .then((response) => {
                    if(response.data.data != ""){
                        this.formData = response.data.data;
                    }
                    this.ocultarCarga()
                })
                .catch((error) => {
                    this.showAlert('warning','Atencion!', error.response.data.error.message);
                });
            },
            async cancelRS(){
                const confirmed = await this.cancelConfirm();
                if (!confirmed) {
                    return;
                }
                location.href = route + 'registro-sanitario';
            },
            mostrarCarga(){
                $('.loading').removeClass('d-none');
                $('#mostrarTablaPedidos').addClass('d-none');
            },
            ocultarCarga(){
                $('.loading').addClass('d-none');
                $('#mostrarTablaPedidos').removeClass('d-none');
            },
            submitForm(){
                this.submit_disabled = true;
                const skus = this.data?.skus?.map(sku => sku.code_sku);
                const distributors = this.data?.distributors?.map(distributor => distributor.code_distributor);
                const dataToSend = {...this.data, skus, distributors};
                // eslint-disable-next-line
                axios.post('/registro-sanitario', Object.fromEntries(Object.entries(dataToSend).filter(([k,v]) => v))
                ).then(() => {
                    this.errorsForm = this.defaultFieldsValues();
                    this.form_success = true;
                })
                .catch((error) => {
                    let message = '';
                    if (error.response.status == 422) {
                        this.errorsForm = error.response.data.errors;
                        message = 'Hay campos invalidos en el formulario.';
                    } else {
                        this.errorsForm = this.defaultFieldsValues();
                        message = error.response.data.error.message.description;
                    }
                    this.showAlert('warning','Atencion!', message);
                })
                .finally(() => {
                    this.submit_disabled = false;
                });
            },
            getUser(){
                axios.get('/auth/user'
                ).then((response) => {        
                    this.user = response.data.data.profile.name;
                    const adminRol = response.data.data.profile.Admin_RS ?? null;
					const userRol = response.data.data.profile.User_RS ?? null;  
                    if (adminRol) {
                        this.rol = adminRol;
                    } else if (userRol)  {
                        location.href= route + "registro-sanitario";
                    } else {
                        location.href= route + "login";
                    }
                })
                .catch((error) => {
                    if (error.code == "ERR_BAD_REQUEST"){
                        location.href= route + "login";
                    }
                });
            },
            defaultFieldsValues() {
                return {}
            },
            defaultSelected() {
                const values = this.defaultFieldsValues();
                values.status_id = 9;
                return values;
            },
            async cancelConfirm() {
                return await this.$vbsModal.confirm({
                    title: "Cancelar registro",
                    message: "Esta seguro que desea cancelar el registro?",
                    leftBtnText: 'No',
                    rightBtnText: 'Si',
                    displayCloseBtn: false
                });
            },
        },		
    }
</script>