import { createRouter, createWebHistory } from 'vue-router'
import IngresoComponent from '../components/pages/IngresoComponent.vue'
import ForgotPassword from '../components/auth/ForgotPassword.vue'
import ResetPassword from '../components/auth/ResetPassword.vue'
import RegistroSanitarioListado from '../components/pages/registro_sanitario/RegistroSanitarioListado.vue'
import RegistroSanitarioNuevo from '../components/pages/registro_sanitario/RegistroSanitarioNuevo.vue'
import RegistroSanitarioEdicion from '../components/pages/registro_sanitario/RegistroSanitarioEdicion.vue'
import RegistroSanitarioDetalle from '../components/pages/registro_sanitario/RegistroSanitarioDetalle.vue'
import RegistroSanitarioSteps from '../components/pages/registro_sanitario/RegistroSanitarioSteps.vue'
import Home from '../components/pages/Home.vue'
import { route } from "../enviroment.js"
import axios from 'axios';

const routes = [
  {
    path: route + 'login',
    name: 'login',
    component: IngresoComponent,
    meta: { requiresAuth: false }
  },
  {
    path: route + '',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: { requiresAuth: false }
  },
  {
    path: route + 'reset-password',
    name: 'reset-password',
    component: ResetPassword,
    meta: { requiresAuth: false }
  },
  {
    path: route + 'registro-sanitario/',
    name: 'registro-sanitario',
    component: RegistroSanitarioListado,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'registro-sanitario-nuevo',
    name: 'registro-sanitario-nuevo',
    component: RegistroSanitarioNuevo,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'registro-sanitario-detalle/:id',
    name: 'registro-sanitario-detalle',
    component: RegistroSanitarioDetalle,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'registro-sanitario-edicion/:id',
    name: 'registro-sanitario-edicion',
    component: RegistroSanitarioEdicion,
    meta: { requiresAuth: true }
  },
  {
    path: route + 'registro-sanitario-steps/:id',
    name: 'registro-sanitario-steps',
    component: RegistroSanitarioSteps,
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

//check for user logged in on auth sections
router.beforeEach((to, from, next) => {
    // instead of having to check every route record with
    if (to.meta.requiresAuth) {
        const sessionDate = localStorage.getItem('expire_session');
        const expireDate = parseInt(sessionDate) + parseInt(2*60*60*1000);
        if (!localStorage.getItem('name') || (expireDate < new Date().getTime())) {
            next({
                path: route + 'login',
                query: { 
                  redirect: to.fullPath 
                }
            });
        } else {
            localStorage.setItem('expire_session', new Date().getTime());
        }
    }
    next();
});

//handle 401 response -> redirect 
axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        if (error.response.config.url.includes('login')) {
          return Promise.reject(error);
        }
        location.href= route + "login";
    } else {
        return Promise.reject(error);
    }
});

axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    config.withCredentials = true;
    config.baseURL = (process.env.NODE_ENV=='production')
      ? '/api/'
      : process.env.VUE_APP_BACKEND_API_URL;
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default router
